<template>
  <div
    class="
      flex
      justify-start
      items-center
      cursor-pointer
      text-xs
      md:text-base
      lg:text-lg
      hover:bg-blue-light
      dark:hover:bg-dark-textAccent dark:hover:text-dark-bgSecondary
      rounded-md
      px-2
      py-2
    "
    :class="[
      isSelected
        ? 'bg-blue-light dark:bg-dark-textAccent text-white dark:text-dark-bgSecondary'
        : ' text-dark-bgSecondary dark:text-white',
    ]"
    @click="onUserSelected(user)"
  >
    <div class="flex-1 font-semibold px-1">{{ user.name }} {{}}</div>
    <span v-if="isNewMess" class="bg-magenta h-2 w-2 rounded-full"></span>
  </div>
</template>

<script>
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import MessagesQuery from "@/graphql/query/Messages";
import MessagesSubcription from "@/graphql/subscription/Messages";
import MessagesMutation from "@/graphql/muation/Messages";
import { toRefs, watch, ref } from "vue";
import { orderBy, some } from "lodash";
export default {
  props: {
    isSelected: {
      type: Boolean,
    },
    user: {
      type: Object,
    },
    onUserSelected: {
      type: Function,
    },
  },
  setup(props, { emit }) {
    const {
      user: { value: userInfo },
      isSelected,
    } = toRefs(props);
    let messageData = [];
    const selectedChat = ref(isSelected);
    const isNewMess = ref(false);
    const variables = {
      conversationId: userInfo.id,
    };
    const { result, subscribeToMore } = useQuery(MessagesQuery, variables, {
      clientId: "supportClient",
    });
    watch(selectedChat, () => {
      isNewMess.value = false;
    });
    messageData = useResult(result, null, data =>
      orderBy(data.getMessages.items, ["createdAt"], ["desc"]),
    );
    subscribeToMore(() => ({
      document: MessagesSubcription,
      variables,
      clientId: "supportClient",
      updateQuery: (
        previousResult,
        {
          subscriptionData: {
            data: { onAddMessage },
          },
        },
      ) => {
        if (onAddMessage.userName === userInfo.partner) {
          isNewMess.value = true;
        }
        if (
          !some(
            previousResult.getMessages.items,
            item => item.id === onAddMessage.id,
          )
        ) {
          previousResult.getMessages.items.push(onAddMessage);
          return previousResult;
        }
      },
    }));
    const { mutate: createMessage } = useMutation(MessagesMutation, {
      clientId: "supportClient",
    });
    const onCreateMessage = ({ body, sender, userName }) => {
      createMessage({
        conversationId: userInfo.id,
        body,
        sender,
        userName,
      });
    };
    watch(messageData, newData => {
      emit("onMessageChanged", {
        userId: userInfo.id,
        data: newData,
      });
    });
    return {
      messageData,
      onCreateMessage,
      selectedChat,
      isNewMess,
    };
  },
};
</script>
