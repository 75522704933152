<template>
  <div>
    <div class="block md:flex justify-end items-center">
      <button class="button_small-Alternative uppercase bg-magenta border rounded-full py-3 px-4 lg:py-4 lg:px-8 focus:outline-none" @click="doCreateCustomer">Create/Import</button>
      <button class="button_small-Alternative uppercase text-magenta border rounded-full py-3 px-4 lg:py-4 lg:px-8 focus:outline-none ml-4" @click="clearCustomers">Clear</button>
    </div>
    <Popup :customer="data.customer" :isNew="data.isNew" :customerList="data.customersListDataTable" @onClose="data.customer = {}" @onUpdate="getCustomers" @onNotice="data.showNotice = true" />
    <Notice :isShow="data.showNotice" @close="data.showNotice = false" />
    <p v-if="data.customersListDataTable?.length === 0 && !data.isLoading" class="w-full text-base lg:text-xl font-semibold text-magenta text-center my-5 font-fs-elliot-pro">No customer</p>
    <div v-else class="w-full">
      <Field
        v-if="!data.isLoading"
        v-model="data.searchInfo"
        @input="debounceSearchData"
        type="text"
        name="search"
        placeholder="Input user info to search"
        class="mt-4 block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
      />
      <table class="text-left w-full">
        <thead>
          <tr>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>First Name</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Last Name</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Email</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Edit</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Remove</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody :class="{ 'animate-pulse': data.isLoading }">
          <template v-if="!data.isLoading">
            <tr
              v-for="(u, index) in data.customersListDataTable"
              :key="index"
              :class="{
                'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
              }"
            >
              <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                {{ u.firstName }}
              </td>
              <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                {{ u.lastName }}
              </td>
              <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                {{ u.email }}
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <EditIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onEdit(u)" />
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onDelete(u.id)" />
              </td>
            </tr>
          </template>

          <template v-if="data.isLoading">
            <tr
              v-for="n in 10"
              :key="n"
              :class="{
                'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
              }"
              class="h-14"
            >
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="data.customersListDataTable.length" class="flex border-t-2 border-grey-light pt-4 lg:pt-7">
        <button
          class="w-16 md:w-20 h-8 md:h-10 text-xs md:text-base uppercase font-bold text-grey-mid dark:text-dark-textPrimary bg-white dark:bg-dark-bgSecondary border-2 border-grey-light dark:border-dark-bgPrimary rounded-full mr-2 focus:outline-none"
          @click.prevent="paginate(1)"
        >
          First
        </button>

        <div v-for="page in totalPages" :key="`pagination-${page}`">
          <span
            v-if="isDisplayPageNumber(page)"
            :class="page === data.currentPage ? 'border-blue-light' : 'border-grey-light dark:border-dark-bgPrimary'"
            class="flex justify-center items-center w-8 md:w-10 h-8 md:h-10 text-grey-mid dark:text-dark-textPrimary rounded-full border-2 shadow-sm cursor-pointer mr-2"
            @click="paginate(page)"
          >
            {{ page }}
          </span>
        </div>

        <button
          class="w-16 md:w-20 h-8 md:h-10 text-xs md:text-base uppercase font-bold text-grey-mid dark:text-dark-textPrimary bg-white dark:bg-dark-bgSecondary border-2 border-grey-light dark:border-dark-bgPrimary rounded-full mr-2 focus:outline-none"
          @click.prevent="paginate(totalPages)"
        >
          Last
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount, nextTick, watch } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate, filterDataCustom } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
// import { deleteUser } from "@API/user/user-repository";
import { useTableData } from "@Libs/hooks/useDataTable";
import { Field } from "vee-validate";

import DeleteIcon from "@Assets/images/DeleteIcon";
import EditIcon from "@Assets/images/EditIcon";
import Popup from "./Popup";
import Notice from "./Notice";

import { deleteCustomer, deleteCustomers } from "@API/event/event-repository";
import { empty } from "@/utils";
import { debounce } from "lodash";

export default {
  name: "UsersTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  components: { EditIcon, DeleteIcon, Popup, Notice, Field },
  emits: ["onUpdate", "toggleLoading"],
  setup(props, { emit }) {
    const store = useStore();
    const data = reactive({
      searchInfo: "",
      curerntFilterText: "",
      currentSort: "email",
      currentSortDir: "asc",
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      customerList: [],
      isNew: false,
      showNotice: false,
      customer: {},
      customersList: [],
      customersListDataTable: [],
    });
    const sortStr = computed({
      get: () => {
        let s = "";
        if (data.currentSortDir === "desc") s += "-";
        return s + data.currentSort;
      },
      set: s => {
        //if s == current sort, reverse
        if (s === data.currentSort) {
          data.currentSortDir = data.currentSortDir === "asc" ? "desc" : "asc";
        } else data.currentSortDir = "asc";
        data.currentSort = s;
      },
    });
    const totalPages = computed(() => Math.ceil(data.customerList.length / data.perPage));
    watch(
      () => data.customerList,
      value => {
        data.searchInfo = "";
        data.customersListDataTable = dynamicPaginate(value, data.currentPage, data.perPage);
      },
    );

    const onSearch = () => {
      let dataRet = dynamicPaginate(data.customerList, data.currentPage, data.perPage);
      if (!empty(data.searchInfo)) {
        dataRet = filterDataCustom(data.searchInfo, data.customerList, ["firstName", "lastName", "email"]);
      }
      data.customersListDataTable = dataRet;
    };
    const debounceSearchData = debounce(onSearch, 300);
    // onBeforeMount(getCustomers);

    async function getCustomers() {
      data.customer = {};
      // Show loading
      toggleLoading();
      const { fetchCustomersListDatatable } = useTableData();

      data.customerList = await fetchCustomersListDatatable(props.eventDetail.id, data.curerntFilterText, sortStr.value);

      // Hide Loading
      nextTick();
      toggleLoading();
    }

    async function clearCustomers() {
      let result;
      try {
        // Show loading
        emit("toggleLoading");
        data.searchInfo = "";

        result = await deleteCustomers(props.eventDetail.id);
        if (!empty(result)) {
          store.dispatch("toggleAlert", {
            isAlert: true,
            status: result.success,
            title: result.success ? "Success!" : "Failed!",
            message: result.message,
          });
        } else {
          store.dispatch("toggleAlert", {
            isAlert: true,
            status: false,
            title: "Failed!",
            message: "Clear customers failed!",
          });
        }
        // Hide Loading
        nextTick();
        getCustomers();
      } catch (error) {
        console.log({ error });
      } finally {
        emit("toggleLoading", false);
        await sleep(5000);

        // Hide Alert
        store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading() {
      data.isLoading = !data.isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    function onDelete(customerId) {
      // Show confirm dialog
      store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-delete-customer",
        isConfirmDialog: true,
        title: "Delete viewer account?",
        message: "Are you sure you want to remove viewer account? All of viewer account data will be permanently removed. This action cannot be undone.",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "REMOVE_CUSTOMER",
          payload: customerId,
        },
      });
    }

    function onEdit(customer) {
      data.isNew = false;
      data.customer = customer;
    }

    function doCreateCustomer() {
      data.isNew = true;
      data.customer = {
        fistName: "",
        lastName: "",
        email: "",
        password: "",
      };
    }

    return { debounceSearchData, data, totalPages, paginate, isDisplayPageNumber, onDelete, onEdit, doCreateCustomer, getCustomers, clearCustomers };
  },
  watch: {
    isActive(value) {
      if (value) {
        this.getCustomers();
        this.emitter.all.delete("submit-confirm-dialog-delete-customer");
        // Listen submit confirm dialog
        this.emitter.on("submit-confirm-dialog-delete-customer", async () => {
          const { type, payload } = this.$store.state.auth.confirmDialog.confirmAction;
          if (type === "REMOVE_CUSTOMER") {
            this.$store.dispatch("updateLoadingText", "Removing viewer account");
            this.$emit("toggleLoading");
            const result = await deleteCustomer(this.eventDetail.id, payload);
            this.getCustomers();
            // Show Alert
            this.$store.dispatch("toggleAlert", {
              isAlert: true,
              status: result.success,
              title: result.success ? "Success!" : "Failed!",
              message: result.message,
            });
            this.$emit("toggleLoading", false);

            this.$store.dispatch("resetConfirmDialog");

            await sleep(5000);

            // Hide Alert
            this.$store.dispatch("toggleAlert", {
              isAlert: false,
            });
          }
        });
      }
    },
  },
};
</script>
